<template>
  <!-- <div class="login"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
  <div class="login-box">
    <div class="title">欢迎登录在线备课系统</div>
    <input
      type="text"
      id="user"
      v-model="user"
      placeholder="请输入手机号"
      autocomplete="off"
    />
    <input
      type="password"
      id="pwd"
      v-model="pwd"
      placeholder="登录密码"
      autocomplete="off"
    />
    <div class="choose">
      <!-- <el-checkbox v-model="checked" class="left">记住密码</el-checkbox> -->
      <div class="right">
        <router-link to="/login/find"><span>忘记密码</span></router-link> |
        <router-link to="/login/register"><span>注册</span></router-link>
      </div>
    </div>
    <div class="login-btn" @click="toMain">
      <img src="../../img/登录_slices/矩形 2 拷贝 2.png" alt="" />
      <span>登录</span>
    </div>
    <div class="line">
      <div class="line1"></div>
      <div class="text">第三方登录</div>
      <div class="line1"></div>
    </div>
    <div class="login-wx" @click="wxLogin">
      <img src="../../img/登录_slices/微信.png" alt="" />
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
// import $ from "jquery";
import * as util from "@/unit/network";
export default {
  name: "Login",
  data() {
    return {
      checked: false,
      user: "",
      pwd: "",
    };
  },
  mounted() {
    // this.Login();
    // this.setCookie()
  },
  methods: {
    // 登录成功 跳转main
    toMain() {
      // this.Login();
      util.post("/login", { phone: this.user, password: this.pwd }, (res) => {
        let that = this;
        // localStorage.setItem("token", res.data.token);
        this.setCookie("token", res.data.token, 1);
        if (res.code == 1) {
          this.$message({
            message: res.msg,
            type: "success",
            duration: 500,
            onClose() {
              that.$router.push("/main");
            },
          });
        } else {
          return;
        }
      });
    },
    // 设置cookie
    setCookie(name, value, type) {
      var Days = 1;
      var exp = new Date();
      exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
      var x;
      if (type) {
        x = new Date(
          new Date(new Date().toLocaleDateString()).getTime() +
            24 * 60 * 60 * 1000 -
            1
        );
      } else {
        x = exp.toGMTString();
      }
      document.cookie =
        name + "=" + escape(value) + ";expires=" + x + ";path=/";
    },
    // 微信注册
    wxLogin() {
      this.$router.push("/login/wxcode");
    },
  },
  components: {
    // HelloWorld,
  },
};
</script>
<style lang="less" scoped>
.login-box {
  width: 630/96in;
  height: 738/96in;
  background: url("../../img/登录_slices/组3.png") no-repeat;
  position: fixed;
  top: 50%;
  transform: translateY(-49%);
  right: 111px;
  text-align: center;
  padding: 108/96in 90/96in 80/96in;
  .title {
    font-size: 30/96in;
    font-weight: bold;
    color: #121212;
    height: 30/96in;
    line-height: 30/96in;
    letter-spacing: 0.1em;
  }
  input {
    width: 450/96in;
    height: 58/96in;
    box-shadow: 0 12/96in 29/96in 3/96in rgba(35, 24, 21, 0.08);
    border-radius: 5/96in;
    padding-left: 54/96in;
    border: none;
    outline: none;
    font-size: 16/96in;
    font-weight: 400;
    color: #000;
    &#user {
      background: rgba(253, 253, 253, 0.75)
        url("../../img/登录_slices/图标子.png") 19/96in no-repeat;
      margin: 63/96in 0 60/96in 0;
    }
    &#pwd {
      background: rgba(253, 253, 253, 0.75)
        url("../../img/登录_slices/密码 .png") 19/96in no-repeat;
      margin-bottom: 48/96in;
    }
  }
  // 记住密码
  .choose {
    display: flex;
    justify-content: flex-end;
    padding: 0 13/96in;
    color: #313339;
    font-size: 14/96in;
    margin-bottom: 10/96in;
    height: 18/96in;
    // label {
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    //   /deep/ .el-checkbox__label {
    //     font-size: 14/96in;
    //     padding-left: 20/96in;
    //     color: #313339;
    //   }
    // }
    .right {
      span {
        cursor: pointer;
      }
    }
  }
  // 登录
  .login-btn {
    position: relative;
    height: 116/96in;
    margin-bottom: 3/96in;
    cursor: pointer;
    img {
      position: absolute;
      left: -29/96in;
    }
    span {
      position: absolute;
      color: #edeef5;
      font-size: 20/96in;
      left: 50%;
      top: 36/96in;
      transform: translateX(-50%);
    }
  }
  // line
  .line {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14/96in;
    color: #313339;
    height: 14/96in;
    .line1 {
      width: 150/96in;
      height: 2/96in;
      background: #c1c1c1;
    }
    .text {
      margin: 0 27/96in;
    }
  }
  .login-wx {
    margin-top: 23/96in;
    height: 46/96in;
    cursor: pointer;
  }
}
</style>
